import React from 'react';
import CartPage from '~/components/Cart/Page';
import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';

const Cart = () => (
  <Layout>
    <Metadata title="Cart" />
    <CartPage />
  </Layout>
);

export default Cart;
